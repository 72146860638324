import { resolveComponent as _resolveComponent, createVNode as _createVNode, createElementVNode as _createElementVNode, withCtx as _withCtx, toDisplayString as _toDisplayString, normalizeClass as _normalizeClass, openBlock as _openBlock, createElementBlock as _createElementBlock, createCommentVNode as _createCommentVNode, renderList as _renderList, Fragment as _Fragment, createBlock as _createBlock } from "vue"

const _hoisted_1 = { key: 0 }
const _hoisted_2 = { class: "row" }
const _hoisted_3 = { class: "text-h4 forum-post-title q-mb-lg" }
const _hoisted_4 = { class: "row items-center" }
const _hoisted_5 = ["src"]
const _hoisted_6 = { class: "q-mt-xs" }
const _hoisted_7 = { class: "forum-post-created-by text-subtitle1 text-weight-bold" }
const _hoisted_8 = { class: "forum-post-created-at text-subtitle2 text-weight-light" }
const _hoisted_9 = { class: "forum-post-contents text-body1 q-mt-lg" }
const _hoisted_10 = {
  key: 0,
  class: "row justify-start"
}
const _hoisted_11 = { class: "forum-post-comment-section q-mt-lg" }
const _hoisted_12 = { class: "forum-post-add-comment" }
const _hoisted_13 = { class: "row q-mt-md justify-end items-center" }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_spinner = _resolveComponent("spinner")!
  const _component_translatable_content = _resolveComponent("translatable-content")!
  const _component_q_avatar = _resolveComponent("q-avatar")!
  const _component_q_btn = _resolveComponent("q-btn")!
  const _component_delete_confirm_dialog = _resolveComponent("delete-confirm-dialog")!
  const _component_q_separator = _resolveComponent("q-separator")!
  const _component_forum_comment_details = _resolveComponent("forum-comment-details")!
  const _component_errors = _resolveComponent("errors")!
  const _component_q_card_section = _resolveComponent("q-card-section")!
  const _component_translatable_input = _resolveComponent("translatable-input")!
  const _component_language_selector = _resolveComponent("language-selector")!
  const _component_q_card = _resolveComponent("q-card")!
  const _component_q_page = _resolveComponent("q-page")!

  return (_openBlock(), _createBlock(_component_q_page, null, {
    default: _withCtx(() => [
      _createVNode(_component_spinner, {
        modelValue: _ctx.loading,
        "onUpdate:modelValue": _cache[0] || (_cache[0] = ($event: any) => ((_ctx.loading) = $event))
      }, null, 8, ["modelValue"]),
      (!_ctx.loading)
        ? (_openBlock(), _createElementBlock("div", _hoisted_1, [
            _createElementVNode("div", _hoisted_2, [
              _createElementVNode("div", _hoisted_3, [
                _createVNode(_component_translatable_content, {
                  text: _ctx.forumPost.title
                }, null, 8, ["text"])
              ])
            ]),
            _createElementVNode("div", _hoisted_4, [
              _createVNode(_component_q_avatar, { class: "q-mr-md" }, {
                default: _withCtx(() => [
                  _createElementVNode("img", {
                    src: _ctx.forumPost.user.avatar
                  }, null, 8, _hoisted_5)
                ]),
                _: 1
              }),
              _createElementVNode("div", _hoisted_6, [
                _createElementVNode("div", _hoisted_7, _toDisplayString(_ctx.forumPost.user.name), 1),
                _createElementVNode("div", _hoisted_8, _toDisplayString(_ctx.formatDatetime(_ctx.forumPost.created_at)), 1)
              ])
            ]),
            _createElementVNode("div", _hoisted_9, [
              _createVNode(_component_translatable_content, {
                text: _ctx.forumPost.contents
              }, null, 8, ["text"])
            ]),
            (_ctx.isAdmin || _ctx.forumPost.user.uuid === _ctx.user.uuid)
              ? (_openBlock(), _createElementBlock("div", _hoisted_10, [
                  _createVNode(_component_q_btn, {
                    color: "negative",
                    label: _ctx.t('Delete post'),
                    onClick: _cache[1] || (_cache[1] = ($event: any) => (_ctx.showDeleteForumPostConfirmDialog = true)),
                    class: _normalizeClass({
                        'q-mt-md': true,
                        'full-width': this.$q.screen.width < 377,
                    })
                  }, null, 8, ["label", "class"])
                ]))
              : _createCommentVNode("", true),
            _createVNode(_component_delete_confirm_dialog, {
              visible: _ctx.showDeleteForumPostConfirmDialog,
              "onUpdate:visible": _cache[2] || (_cache[2] = ($event: any) => ((_ctx.showDeleteForumPostConfirmDialog) = $event)),
              onDelete: _ctx.deletePost
            }, null, 8, ["visible", "onDelete"]),
            _createVNode(_component_q_separator, { class: "q-mt-lg q-mb-lg" }),
            _createElementVNode("div", _hoisted_11, [
              (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(_ctx.forumPost.comments, (comment, idx) => {
                return (_openBlock(), _createBlock(_component_forum_comment_details, {
                  onDeleteComment: _cache[3] || (_cache[3] = ({ commentUuid }) => _ctx.deleteComment(commentUuid)),
                  comment: comment,
                  key: idx
                }, null, 8, ["comment"]))
              }), 128))
            ]),
            _createVNode(_component_q_card, { flat: "" }, {
              default: _withCtx(() => [
                (_ctx.errors.length > 0)
                  ? (_openBlock(), _createBlock(_component_q_card_section, { key: 0 }, {
                      default: _withCtx(() => [
                        _createVNode(_component_errors, { errors: _ctx.errors }, null, 8, ["errors"])
                      ]),
                      _: 1
                    }))
                  : _createCommentVNode("", true),
                _createElementVNode("div", _hoisted_12, [
                  _createVNode(_component_translatable_input, {
                    modelValue: _ctx.newCommentText,
                    "onUpdate:modelValue": _cache[4] || (_cache[4] = ($event: any) => ((_ctx.newCommentText) = $event)),
                    "is-valid": _ctx.isValidComment,
                    "onUpdate:is-valid": _cache[5] || (_cache[5] = ($event: any) => ((_ctx.isValidComment) = $event)),
                    language: _ctx.language,
                    title: _ctx.t('Add comment'),
                    type: "textarea",
                    optional: ""
                  }, null, 8, ["modelValue", "is-valid", "language", "title"]),
                  _createElementVNode("div", _hoisted_13, [
                    _createVNode(_component_language_selector, {
                      modelValue: _ctx.language,
                      "onUpdate:modelValue": _cache[6] || (_cache[6] = ($event: any) => ((_ctx.language) = $event))
                    }, null, 8, ["modelValue"]),
                    _createVNode(_component_q_btn, {
                      color: "secondary",
                      loading: _ctx.postingNewComment,
                      label: _ctx.t('Add comment'),
                      onClick: _ctx.addNewComment,
                      class: _normalizeClass({
                                'q-mt-sm': this.$q.screen.width < 377,
                                'full-width': this.$q.screen.width < 377,
                            })
                    }, null, 8, ["loading", "label", "onClick", "class"])
                  ])
                ])
              ]),
              _: 1
            })
          ]))
        : _createCommentVNode("", true)
    ]),
    _: 1
  }))
}