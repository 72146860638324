import { createElementVNode as _createElementVNode, resolveComponent as _resolveComponent, withCtx as _withCtx, createVNode as _createVNode, toDisplayString as _toDisplayString, openBlock as _openBlock, createElementBlock as _createElementBlock, createCommentVNode as _createCommentVNode, createBlock as _createBlock } from "vue"

const _hoisted_1 = { class: "row justify-between" }
const _hoisted_2 = { class: "row col items-center" }
const _hoisted_3 = ["src"]
const _hoisted_4 = { class: "q-mt-xs" }
const _hoisted_5 = { class: "text-weight-bold" }
const _hoisted_6 = { class: "text-weight-thin" }
const _hoisted_7 = { key: 0 }
const _hoisted_8 = { class: "text-body-2 q-mt-md" }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_q_avatar = _resolveComponent("q-avatar")!
  const _component_q_btn = _resolveComponent("q-btn")!
  const _component_delete_confirm_dialog = _resolveComponent("delete-confirm-dialog")!
  const _component_translatable_content = _resolveComponent("translatable-content")!
  const _component_q_card_section = _resolveComponent("q-card-section")!
  const _component_q_card = _resolveComponent("q-card")!

  return (_openBlock(), _createBlock(_component_q_card, { class: "q-mt-md q-mb-md" }, {
    default: _withCtx(() => [
      _createVNode(_component_q_card_section, null, {
        default: _withCtx(() => [
          _createElementVNode("div", _hoisted_1, [
            _createElementVNode("div", _hoisted_2, [
              _createVNode(_component_q_avatar, { class: "q-mr-md" }, {
                default: _withCtx(() => [
                  _createElementVNode("img", {
                    src: _ctx.comment.user.avatar
                  }, null, 8, _hoisted_3)
                ]),
                _: 1
              }),
              _createElementVNode("div", _hoisted_4, [
                _createElementVNode("div", _hoisted_5, _toDisplayString(_ctx.comment.user.name), 1),
                _createElementVNode("div", _hoisted_6, _toDisplayString(_ctx.formatDatetime(_ctx.comment.created_at)), 1)
              ])
            ]),
            (_ctx.isAdmin || _ctx.comment.user.uuid === _ctx.user.uuid)
              ? (_openBlock(), _createElementBlock("div", _hoisted_7, [
                  _createVNode(_component_q_btn, {
                    style: {"width":"40px"},
                    color: "negative",
                    icon: "delete",
                    flat: "",
                    round: "",
                    onClick: _cache[0] || (_cache[0] = ($event: any) => (_ctx.showDeleteForumPostConfirmDialog = true))
                  })
                ]))
              : _createCommentVNode("", true),
            _createVNode(_component_delete_confirm_dialog, {
              visible: _ctx.showDeleteForumPostConfirmDialog,
              "onUpdate:visible": _cache[1] || (_cache[1] = ($event: any) => ((_ctx.showDeleteForumPostConfirmDialog) = $event)),
              onDelete: _ctx.deleteComment
            }, null, 8, ["visible", "onDelete"])
          ]),
          _createElementVNode("div", _hoisted_8, [
            _createVNode(_component_translatable_content, {
              text: _ctx.comment.contents
            }, null, 8, ["text"])
          ])
        ]),
        _: 1
      })
    ]),
    _: 1
  }))
}