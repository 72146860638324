
import { ApiValidationError } from '@/api/core/api';
import User from '@/api/models/User';
import FileSelector from '@/components/Interaction/FileSelector.vue';
import LanguageToggle from '@/components/Interaction/LanguageToggle.vue';
import Errors from '@/components/Notifications/Errors.vue';
import DateSelector from '@/components/Selectors/DateSelector.vue';
import Spinner from '@/components/Util/Spinner.vue';
import { MutationTypes } from '@/store/mutations';
import { defineComponent, ref, Ref, computed } from '@vue/runtime-core';
import { useI18n } from 'vue-i18n';
import { useStore } from 'vuex';
import { required, strongPassword, maxLength, email } from '@/validation/validation';
import QForm from 'quasar/src/components/form/QForm.js';;

export default defineComponent({
    components: { Errors, LanguageToggle, Spinner, DateSelector, FileSelector },
    name: 'AccountInformation',
    setup() {
        const { t } = useI18n();
        const store = useStore();

        const isProcessing = ref(false);
        const newAvatar: Ref<File | null> = ref(null);
        const uploadNewAvatarErrors: Ref<string[]> = ref([]);
        const user = computed(() => store.getters.user);

        const uploadNewAvatar = async () => {
            if (newAvatar.value === null) {
                uploadNewAvatarErrors.value = [t('Please choose an image first')];
                return;
            }

            isProcessing.value = true;
            uploadNewAvatarErrors.value = [];

            try {
                store.commit(MutationTypes.setUser, await User.uploadAvatar(newAvatar.value));
                newAvatar.value = null;
            } catch (e) {
                if (e instanceof ApiValidationError) {
                    uploadNewAvatarErrors.value = e.getErrors();
                } else {
                    throw e;
                }
            } finally {
                isProcessing.value = false;
            }
        };

        const submitPersonalInformationErrors: Ref<string[]> = ref([]);
        const submitPersonalInformation = async () => {
            isProcessing.value = true;
            submitPersonalInformationErrors.value = [];

            try {
                store.commit(MutationTypes.setUser, await user.value.save());
            } catch (e) {
                if (e instanceof ApiValidationError) {
                    submitPersonalInformationErrors.value = e.getErrors();
                } else {
                    throw e;
                }
            } finally {
                isProcessing.value = false;
            }
        };

        const passwordResetForm = ref<QForm | null>(null);

        const resetChangePasswordValidationForm = () => {
            passwordResetForm.value?.resetValidation();
        };

        const password = ref({
            current: '',
            new: '',
            confirmation: '',
        });

        const submitNewPasswordErrors: Ref<string[]> = ref([]);
        const submitNewPassword = async () => {
            isProcessing.value = true;
            submitNewPasswordErrors.value = [];

            try {
                await user.value.updatePassword(password.value);
                password.value.current = '';
                password.value.new = '';
                password.value.confirmation = '';
                setTimeout(resetChangePasswordValidationForm, 50);
            } catch (e) {
                if (e instanceof ApiValidationError) {
                    submitNewPasswordErrors.value = e.getErrors();
                }
            } finally {
                isProcessing.value = false;
            }
        };

        const equalToNewPassword = () =>
            password.value.new === password.value.confirmation ||
            t('Password confirmation is not equal to new password.');

        return {
            t,
            isProcessing,
            newAvatar,
            uploadNewAvatarErrors,
            uploadNewAvatar,
            user,
            submitPersonalInformationErrors,
            submitPersonalInformation,
            password,
            submitNewPasswordErrors,
            submitNewPassword,
            required,
            strongPassword,
            equalToNewPassword,
            maxLength,
            email,
            passwordResetForm,
            resetChangePasswordValidationForm,
        };
    },
});
